<template>
<span v-if="orderData.delivery.delivery_method === 'ukr-posta'">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="delivery_type">{{ $t('delivery_type') }}<span class="text-danger">*</span></label>
        <select
        v-model="orderData.delivery.delivery_type"
        name="delivery_type"
        class="form-control"
        :disabled="!editOn || printed">
          <option v-for="(item, index) in deliveryTypes[orderData.delivery.delivery_method].methods" :value="item.id" :key="index">{{ item.name }}</option>
        </select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label for="delivery_type">{{ $t('shipment_type') }}<span class="text-danger">*</span></label>
        <select
        v-model="orderData.delivery.shipment_type"
        name="shipment_type"
        class="form-control"
        :disabled="!editOn || printed">
          <option v-for="(item, index) in deliveryTypes[orderData.delivery.delivery_method].types" :value="item.id" :key="index">{{ item.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="check_on_delivery_allowed">{{ $t('checkOnDeliveryAllowed') }}<span class="text-danger">*</span></label>
    <select
    v-model="orderData.delivery.check_on_delivery_allowed"
    name="check_on_delivery_allowed"
    class="form-control"
    :disabled="!editOn || printed">
      <option value="true">{{ $t('yes') }}</option>
      <option value="false">{{ $t('no') }}</option>
    </select>
  </div>
</span>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      orderData: 'order/order',
      deliveryTypes: 'order/deliveryTypes',
      editOn: 'order/editDeliveryData',
      printed: 'order/printed'
    })
  }
}
</script>
